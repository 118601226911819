/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import { version } from '../../package.json'
import Header from "./header"
import { Email, Phone, Cell } from "./contactData"
// import "./layout.css"

console.log(`Physiotherapie mitbewegt - ${version}`)

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      <main>{children}</main>

      <footer className="footer">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="content footer-menu">
                <Link className="has-text-grey-dark" to="/leistungen#preisliste">Preisliste</Link><br />
                <Link className="has-text-grey-dark" to="/leistungen#termin-absage">Terminabsage</Link><br />
                <Link className="has-text-grey-dark" to="/impressum">Impressum</Link><br />
                <Link className="has-text-grey-dark" to="/datenschutz">Datenschutz­erklärung</Link><br />
                <Link className="has-text-grey-dark" to="/datenschutzeinstellungen">Datenschutzeinstellungen</Link><br />
              </div>
            </div>

            <div className="column">
              <b>mitbewegt - Praxis für Physiotherapie</b><br />
              Holbeinstr. 24<br />
              04229 Leipzig (Schleußig)<br /><br />
              Festnetz: <Phone /><br />
              Mobil: <Cell /><br />
              <Email /><br />
            </div>
            <div className="column">
              <b>Öffnungszeiten</b><br />
              <table className="clean">
                <tbody>
                  <tr>
                    <td>Montag</td><td>8-15 Uhr</td>
                  </tr>
                  <tr>
                    <td>Dienstag</td><td>9-19 Uhr</td>
                  </tr>
                  <tr>
                    <td>Mittwoch</td><td>8-15 Uhr</td>
                  </tr>
                  <tr>
                    <td>Donnerstag</td><td>9-19 Uhr</td>
                  </tr>
                  <tr>
                    <td>Freitag</td><td>8-14 Uhr</td>
                  </tr>
                </tbody>
              </table>
              <p className="mt-3">und nach Vereinbarung</p>

            </div>
          </div>
        </div>

        <div className="mt-6 content has-text-centered has-text-grey is-italicis-size-7">
          2021 - {new Date().getFullYear()} Phyisotherapie mitbewegt
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
