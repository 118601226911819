import React from 'react'

const email = ['mitbewegt', 'gmx', 'de']

const triggerMailTo = (e) => {
    e.preventDefault()
    window.location.href = 'mailto:' + email[0] + '@' + email[1] + '.' + email[2]
}

export const Email = (props) => (
    <button className="cryptedmail button is-ghost p-0"
        data-name={email[0]}
        data-domain={email[1]}
        data-tld={email[2]}
        onClick={triggerMailTo}
        {...props}
    ></button>
)

export const Phone = (props) => (
    <a href="tel:+4934123159742" {...props}>0341 231 597 42</a>
)

export const Cell = (props) => (
    <a href="tel:+4917681113155" {...props}>0176 811 131 55</a>
)
