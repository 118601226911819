import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from '@reach/router';

import "./style.scss"

const navItems = [
  {
    name: 'Startseite',
    urlPath: '/'
  },
  {
    name: 'Leistungen',
    urlPath: '/leistungen'
  },
  {
    name: 'Über Mich',
    urlPath: '/ubermich'
  },
  {
    name: 'Kontakt',
    urlPath: '/kontakt'
  }
]

const IsHomepage = () => {
  const location = useLocation();

  return location.pathname === '/'
}

const Header = () => {
  const [activeMenu, setActiveMenu] = React.useState(false)

  const mobileMenuHandler = (e) => {
    e.preventDefault()
    setActiveMenu(!activeMenu)
  }

  return (
    <header className="test">
      <div className="container">
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/" >
              <StaticImage
                src="../images/mitbewegt-color.png"
                height={80}
                loading="eager"
                placeholder="none"
                alt="Logo der Physiotherapie Praxis mitbewegt (bunt)"
              />
            </a>

            <a role="button" href="/" className={`navbar-burger ${activeMenu ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={mobileMenuHandler}>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </a>
          </div>

          <div className={`navbar-menu ${activeMenu ? 'is-active' : ''}`}>
            <div className="navbar-end">
              <div className="navbar-item is-size-5">
                {
                  navItems.map(n => (
                    <Link
                      key={n.urlPath}
                      // partiallyActive={true}
                      className={`navbar-item`}
                      activeClassName="navbar-item is-active"
                      to={n.urlPath}>
                      {n.name}
                    </Link>
                  ))
                }
              </div>
            </div>
          </div>
        </nav>
      </div>

      {IsHomepage() && (
        <>
          <svg width="0" height="0">
            <defs>
              <clipPath id="header-curve" clipPathUnits="objectBoundingBox">
                <path d="M 0,0 
              L 1,0
              Q 0.7,0.2 0.8,0.5
              Q 0.9,0.7 0.65 1
              L 1,1
              L 0 1
              "></path>
              </clipPath>
            </defs>
          </svg>

          <section className="hero is-small hero-custom">
            <div className="hero-image-wrapper">
              <StaticImage
                src="../images/fluss.jpg"
                quality={40}
                loading="eager"
                placeholder="blurred"
                alt="Fluss läuft durch eine Wald mit vielen Bäumen an beiden Ufern"
              />
            </div>
            <div className="hero-body1" >

              <div className="text-container has-text-right has-text-dark	">
                <span className="header-small is-size-3 is-size-5-mobile">Willkommen in der</span>
                <span className="header-bold">Physiotherapie</span>
                <span className="header-bold has-text-weight-bold">mitbewegt</span>
                <p className="header-text">
                  Jeder Mensch hat eigene Ressourcen und Energien, welche eine Selbstheilung ermöglichen. Ich freue mich Sie auf dem Weg der Entdeckung und Mobilisierung Ihrer eigenen Kräfte zu begleiten.
                </p>

                <a href="/leistungen" className="button is-dark is-rounded">Angebotene Leistungen</a>
              </div>
            </div>
          </section>
        </>
      )}

    </header >
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
